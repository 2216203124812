.main-section {
  display: flex;
  flex-direction: column;
  box-sizing: inherit;
  background-color: white;
  border: 5px thin grey;
  border-radius: 4px;
}

.app-header{
  background-color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: white;
  padding: 10px;
}

.app-footer{
  background-color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: white;
  padding: 10px;
}

.app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
}

.logo-image {
  width: 275px;
  margin: 15px;
}

.un-autre-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: x-large;
  margin: 10px;
  padding-bottom: 50px;
  color: black;
}

.instagram-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
  justify-content: space-around;
  border: 5px solid #384050;
  border-radius: 4px;
  background-color: #29303e;
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.instagram-box p {
  margin: 0;
  font-size: xx-large;
}

.instagram-box img {
  width: 100px;
  margin-left: 10px;
}

.instagram-box:hover {
  opacity: 0.9;
}
